import { FormattedMessage } from "react-intl";
import { useState } from "react";
import classNames from "classnames";

import { DeprecatedDetailGrid } from "common/details/grid";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { useQuery } from "util/graphql";
import FormattedAddress from "common/core/format/address";
import { MailtoLink } from "common/core/mailto_link";
import Icon from "common/core/icon";
import { PhotoIdVerificationStatus, type CompletionRequirement } from "graphql_globals";
import IdImage from "common/core/customer_id/id_image";
import { CAPTURE_ID_TYPE, CAPTURE_ID_SIDE } from "constants/id_validation";
import IdSideToggle from "common/core/customer_id/id_side_toggle";
import IdToggle from "common/core/customer_id/id_toggle";
import CustomerIdModal from "common/core/customer_id/modal";
import { CheckboxGroup } from "common/core/form/option";
import Checkbox from "common/form/fields/checkbox";
import LoadingIndicator from "common/core/loading_indicator";
import ValidationStatus from "common/signer/items/validation_status";
import { usePermissions } from "common/core/current_user_role";
import CredentialStatus from "common/signer/items/credential_status";
import { isCredentialAnalysisRequired } from "util/meeting";
import ActionButton from "common/core/action_button";
import FormGroupErrors from "common/form/group_errors";

import type {
  QualityControlDocumentBundle_meetings_edges_node_meetingParticipants_SignerParticipant as SignerParticipant,
  QualityControlDocumentBundle_meetings_edges_node_meetingParticipants as MeetingParticipant,
} from "./quality_control_document_bundle_fragment.graphql";
import QualityControlSignerIdentityQuery from "./quality_control_signer_identity_query.graphql";
import Styles from "./index.module.scss";

const NA_ELEM = <FormattedMessage id="525d1835-c7bd-4ee4-88cd-8c925fd8b298" defaultMessage="N/A" />;

type SignerColumnsProps = {
  meetingParticipants: MeetingParticipant[];
  signerParticipants: SignerParticipant[];
} & BaseProps;

type BaseProps = {
  completionRequirements: (CompletionRequirement | null)[];
  notaryState: string;
};

function SignerQuestions({ activeSignerId }: { activeSignerId: string }) {
  return (
    <div>
      <CheckboxGroup
        label={
          <div className={Styles.questionTitle}>
            <FormattedMessage
              id="a9d162d1-e662-4cfb-9a90-7f6c1e851f45"
              defaultMessage="Signer ID Issues"
            />
          </div>
        }
      >
        <div className={Styles.checkboxes}>
          <Checkbox
            label={
              <FormattedMessage id="698338b4-0dd6-4319-9a8b-3897681d2bd5" defaultMessage="None" />
            }
            value="none"
            name={`signerIdIssues[${activeSignerId}].none`}
          />
          <Checkbox
            label={
              <FormattedMessage
                id="e6c7c944-0cc5-44db-9bde-44cfda200b88"
                defaultMessage="Signer's ID unacceptable per state law"
              />
            }
            value="Signer's ID unacceptable per state law"
            name={`signerIdIssues[${activeSignerId}].idUnacceptablePerStateLaw`}
          />
          <Checkbox
            label={
              <FormattedMessage
                id="cdbea619-8bef-4ea5-801b-e9a37c019eb7"
                defaultMessage="Signer's ID unacceptable per document requirements (e.g. PS1583)"
              />
            }
            value="Signer's ID unacceptable per document requirements (e.g. PS1583)"
            name={`signerIdIssues[${activeSignerId}].idUnacceptablePerDocumentRequirement`}
          />
          <Checkbox
            label={
              <FormattedMessage
                id="2f8138ff-7923-4c89-98eb-709a8dbf956b"
                defaultMessage="Signer's ID not clearly visible or cut off"
              />
            }
            value="Signer's ID not clearly visible or cut off"
            name={`signerIdIssues[${activeSignerId}].idNotClearlyVisibleOrCutOff`}
          />
          <Checkbox
            label={
              <FormattedMessage
                id="88615230-8e28-47c9-9aca-5f7fab64dc18"
                defaultMessage="Signer's ID is a copy of an actual ID"
              />
            }
            value="Signer's ID is a copy of an actual ID"
            name={`signerIdIssues[${activeSignerId}].idIsACopyOfActualId`}
          />
          <Checkbox
            label={
              <FormattedMessage
                id="10a0945e-6f77-48ea-af36-e1ddc27ff461"
                defaultMessage="Person appearing before notary was not the one who was identified in KBA or shown on the ID (i.e. fraud)"
              />
            }
            name={`signerIdIssues[${activeSignerId}].personDoesNotMatchPersonInKbaOrId`}
          />
          <Checkbox
            label={
              <FormattedMessage
                id="71e42e53-b626-4456-89fa-ae2c9d91b5db"
                defaultMessage="Notary added signer with “add witness” feature"
              />
            }
            name={`signerIdIssues[${activeSignerId}].notaryAddedSignerWithAddWitnessFeature`}
          />
        </div>
      </CheckboxGroup>
      <FormGroupErrors fields={[`signerIdIssues_${activeSignerId}`]} />
    </div>
  );
}

function SignerGrid({
  signerParticipant,
  validatedByCredibleWitness,
  completionRequirements,
  notaryState,
}: {
  signerParticipant: SignerParticipant;
  validatedByCredibleWitness: boolean;
} & BaseProps) {
  const { data, loading } = useQuery(QualityControlSignerIdentityQuery, {
    variables: { signerIdentityId: signerParticipant.signerIdentityId },
  });

  const [id, setId] = useState({
    type: CAPTURE_ID_TYPE.PRIMARY as ObjectValues<typeof CAPTURE_ID_TYPE>,
    side: CAPTURE_ID_SIDE.FRONT as ObjectValues<typeof CAPTURE_ID_SIDE>,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const { hasPermissionFor } = usePermissions();

  if (loading || !data?.signerIdentity || data.signerIdentity.__typename !== "SignerIdentity") {
    return <LoadingIndicator />;
  }

  return (
    <DeprecatedDetailGrid>
      <DeprecatedDetailGridRow
        className={Styles.row}
        title={
          <div className={Styles.rowTitle}>
            <FormattedMessage id="6f967aee-2425-4d4a-af1f-94b052364c7b" defaultMessage="Signer 1" />
          </div>
        }
      >
        {signerParticipant.fullName}
      </DeprecatedDetailGridRow>
      <DeprecatedDetailGridRow
        className={Styles.row}
        title={
          <div className={Styles.rowTitle}>
            <FormattedMessage id="aabe04ba-7948-46a6-bc95-70d729f35a7c" defaultMessage="Address" />
          </div>
        }
      >
        {signerParticipant.address.line1 ? (
          <FormattedAddress address={signerParticipant.address} />
        ) : (
          NA_ELEM
        )}
      </DeprecatedDetailGridRow>
      <DeprecatedDetailGridRow
        className={Styles.row}
        title={
          <div className={Styles.rowTitle}>
            <FormattedMessage
              id="143b4d7e-a087-40b4-94dd-30630857aeb6"
              defaultMessage="Email Address"
            />
          </div>
        }
      >
        <MailtoLink emailAddress={signerParticipant.email!} text={signerParticipant.email} />
      </DeprecatedDetailGridRow>
      <DeprecatedDetailGridRow
        className={Styles.row}
        title={
          <div className={Styles.rowTitle}>
            <FormattedMessage id="a5f731a2-acdb-43d6-863c-69139fa952e2" defaultMessage="Meeting" />
          </div>
        }
      />
      {validatedByCredibleWitness ? ( // validated by credible witness
        <DeprecatedDetailGridRow
          className={Styles.row}
          title={
            <div className={Styles.rowTitle}>
              <FormattedMessage
                id="fd666467-e7f5-4876-a02e-6bdc38c4876f"
                defaultMessage="Identification by Credible Witness"
              />
            </div>
          }
        >
          <Icon name="tick" />{" "}
          <FormattedMessage id="921a412e-b6fa-4209-8141-445188e8ab54" defaultMessage="Passed" />
        </DeprecatedDetailGridRow>
      ) : data.signerIdentity.personallyKnownToNotary ? (
        <DeprecatedDetailGridRow
          className={Styles.row}
          title={
            <div className={Styles.rowTitle}>
              <FormattedMessage
                id="3ee464cc-6acc-46df-b17d-b1d1978aff92"
                defaultMessage="Personally Known to Notary"
              />
            </div>
          }
        >
          <Icon name={data.signerIdentity.notaryConfirmedPersonallyKnownToNotary ? "tick" : "x"} />{" "}
          <FormattedMessage
            id="3ee464cc-6acc-46df-b17d-b1d1978aff92"
            defaultMessage="{passed, select, true{Confirmed} other{Unconfirmed}}"
            values={{ passed: data.signerIdentity.notaryConfirmedPersonallyKnownToNotary }}
          />
        </DeprecatedDetailGridRow>
      ) : (
        <div className={Styles.credentialIcons}>
          <ValidationStatus
            titleClassName={Styles.rowTitle}
            completionRequirements={completionRequirements}
          />
          {(hasPermissionFor("credentialVerification") ||
            isCredentialAnalysisRequired(notaryState) ||
            data.signerIdentity.photoId?.statusV2 === PhotoIdVerificationStatus.SUCCESS) && (
            <CredentialStatus
              titleClassName={Styles.rowTitle}
              signerIdentity={data.signerIdentity}
              notaryState={notaryState}
            />
          )}
        </div>
      )}
      {data.signerIdentity.photoId?.frontPicture && data.signerIdentity.photoId.backPicture && (
        <DeprecatedDetailGridRow
          className={Styles.row}
          title={
            <div className={Styles.rowTitle}>
              <FormattedMessage
                id="475080d8-e7d0-41a3-ad9b-629a3cde92d9"
                defaultMessage="Signer Photo ID"
              />
            </div>
          }
        >
          <div className={"Customer-Id-wrapper"}>
            <IdToggle
              hasSecondaryId={!!data.signerIdentity.secondaryId}
              showId={() =>
                id.type === CAPTURE_ID_TYPE.PRIMARY
                  ? setId({ type: CAPTURE_ID_TYPE.SECONDARY, side: CAPTURE_ID_SIDE.FRONT })
                  : setId({ type: CAPTURE_ID_TYPE.PRIMARY, side: CAPTURE_ID_SIDE.FRONT })
              }
              idType={id.type}
            />
            <IdImage
              primaryIdUrl={data.signerIdentity.photoId.frontPicture}
              primaryIdBackUrl={data.signerIdentity.photoId.backPicture}
              secondaryIdUrl={data.signerIdentity.secondaryId?.url}
              idType={id.type}
              idSide={id.side}
              imageLoadedError={false}
              onClick={() => setModalOpen(true)}
              onError={() => {}}
              onLoad={() => {}}
            />
            <div className={Styles.idActions}>
              <IdSideToggle
                idType={id.type}
                idSide={id.side}
                showIdSide={() =>
                  id.side === CAPTURE_ID_SIDE.BACK
                    ? setId({ type: id.type, side: CAPTURE_ID_SIDE.FRONT })
                    : setId({ type: id.type, side: CAPTURE_ID_SIDE.BACK })
                }
              />
            </div>
            {modalOpen && (
              <CustomerIdModal
                primaryIdUrl={data.signerIdentity.photoId.frontPicture}
                primaryIdBackUrl={data.signerIdentity.photoId.backPicture}
                secondaryIdUrl={data.signerIdentity.secondaryId?.url}
                onClose={() => setModalOpen(false)}
                initialIdType={id.type}
                initialIdSide={id.side}
              />
            )}
          </div>
        </DeprecatedDetailGridRow>
      )}
    </DeprecatedDetailGrid>
  );
}

function SignerColumns({
  meetingParticipants,
  completionRequirements,
  signerParticipants,
  notaryState,
}: SignerColumnsProps) {
  const [activeSigner, setActiveSigner] = useState<SignerParticipant | undefined>(
    signerParticipants[0],
  );

  if (!activeSigner) {
    return null;
  }

  const validatedByCredibleWitness = meetingParticipants.some(
    (meetingParticipant) => meetingParticipant.__typename === "WitnessParticipant",
  );

  return (
    <div className={Styles.formColumns}>
      <div className={Styles.info}>
        {signerParticipants.length > 1 && (
          <div className={Styles.signerSectionTitle}>
            {signerParticipants.map((participant) => {
              const active = activeSigner.id === participant.id;
              return (
                <ActionButton
                  className={
                    active ? classNames(Styles.signerName, Styles.active) : Styles.signerName
                  }
                  onClick={() => setActiveSigner(participant)}
                  key={participant.id}
                >
                  {participant.fullName}
                </ActionButton>
              );
            })}
          </div>
        )}
        <div className={Styles.sectionTitle}>
          <FormattedMessage id="9c8fe827-4360-4b60-add7-4f88c49f5d18" defaultMessage="Signer" />
        </div>

        <SignerGrid
          completionRequirements={completionRequirements}
          signerParticipant={activeSigner}
          validatedByCredibleWitness={validatedByCredibleWitness}
          notaryState={notaryState}
        />
      </div>
      <div className={Styles.questions}>
        <SignerQuestions activeSignerId={activeSigner.id} />
      </div>
    </div>
  );
}

export default SignerColumns;
